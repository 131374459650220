<template>
	<!-- <div class="animate-in " :class="{loaded: false, 'is-submitted':isSubmitted}"> -->
	<div class="animate-in " :class="{loaded: isLoaded, 'is-submitted':isSubmitted}">
		<b-form @submit.prevent="submit">
			<h2 class="ul">Case Background</h2>
			<b-form-group
				v-if="showDivision"
				id="fieldset-1"
				label="Division"
				label-for="division"
				label-class="optional"
			>
				<b-form-select
					id="division"
					v-model="$store.state.org.selectedDivision"
					:options="$store.state.org.divisions"
					text-field="name"
					value-field="id"
				></b-form-select>
			</b-form-group>
			<dynamic-form-controls :questions="questions" @change="validate" />
			<div v-if="questions.length" class="text-right form-controls">
				<btn-save v-if="isValid" />
				<b-button type="submit">Next</b-button>
			</div>
		</b-form>
		<!-- <b-form-select  id="radios" v-model="radios" placeholder="Choose division"></b-form-select>
		</b-form-group> -->
	</div>
</template>

<script>
import isQuestionView from '@/mixins/isQuestionView'
export default {
	name: 'Step1',
	mixins: [isQuestionView],
	data() {
		return {
			questionGroup: 'background',
			nextStep: 'step2'
		}
	},
	computed: {
		showDivision() {
			const org = this.$store.state.org
			return org.divisions && org.divisions.length && org.code != 'DFLT'
		}
	},
	async created() {
		this.$store.commit('set', {activityTimer: true})	
		if (this.$store.state.isRetrieving && this.$store.state.login.isLoggedIn) {
			await this.$store.dispatch('login/logout')
			this.loadQuestions()
		} else {
			this.loadQuestions()
		}
	},
	metaInfo: {
		title: 'Submit Report - Step 1',
	}
}
</script>
